import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ComponentsModule } from "./components/components.module";
import { PagesModule } from "./pages/pages.module";
import { MatSelectModule } from "@angular/material/select";
import { AuthService } from "./services/auth/auth.service";
import { AuthGuardService } from "./services/guards/auth-guard.service";
import { DataServiceService } from "./services/data/data-service.service";
import { MarkerService } from "./services/marker/marker.service";
import { environment } from "../../types/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { HttpClientModule } from "@angular/common/http";
import { ChartsModule } from "ng2-charts";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./pages/admin/home/home.component";
import { SubjectComponent } from "./pages/admin/subject/subject.component";
import { MaterialModule } from "./material/material.module";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UsersComponent } from "./pages/admin/users/users.component";
import { StudyModeDialogComponent } from './components/study-mode-dialog/study-mode-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    SubjectComponent,
    UsersComponent,
    StudyModeDialogComponent
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    PagesModule,
    AppRoutingModule,
    ChartsModule,
    HttpClientModule,
    MatSelectModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(
      environment.firebase,
      "angular-auth-firebase"
    ),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    MaterialModule,
    CKEditorModule,
  ],
  providers: [AuthService, AuthGuardService, DataServiceService, MarkerService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    StudyModeDialogComponent
  ],
})
export class AppModule { }
