import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { SubQuestionSelection } from "../../components/sub-question-selection/sub-question-selection.component";
import { QuestionPart, ReviewType } from "../../../../types/types";
import { AuthService } from "../../services/auth/auth.service";
@Component({
  selector: "app-mark-confirm",
  templateUrl: "./mark-confirm.component.html",
  styleUrls: ["./mark-confirm.component.scss"],
})
export class MarkConfirmComponent {
  public subQuestionSelection: SubQuestionSelection = {
    selectedParts: [],
    marks: 0,
  };
  public ReviewType = ReviewType;
  public reviewType: ReviewType = ReviewType.self;
  public creditsRequiredForExamPresure = 30;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MarkDiaglogInput,
    private dialogRef: MatDialogRef<MarkConfirmComponent>,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService
  ) {
    if (!data.beforeStarting) {
      this.reviewType = ReviewType.self;
    }
  }

  close(cancel: boolean) {
    if (cancel) {
      this.reviewType = ReviewType.back;
    }
    const markDiaglogOutput: MarkDiaglogOutput = {
      reviewType: this.reviewType,
      subQuestionSelection: this.subQuestionSelection,
    };
    this.dialogRef.close(markDiaglogOutput);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  setSubQuestionSelection(subQuestionSelection: SubQuestionSelection) {
    this.subQuestionSelection = subQuestionSelection;
  }
}

export interface MarkDiaglogInput {
  subject: string;
  subjectId: string;
  creditsPerMark: number;
  aiMarkingDiscountFactor: number;
  credits: number;
  marks?: number;
  parts?: QuestionPart[];
  acceptingPeerReviews?: boolean;
  tuitionCourseParticipant?: boolean;
  isAssignmentQuestion?: boolean;
  acceptingActuaryReviews?: boolean;
  acceptingAiReviews?: boolean;
  reviewType: ReviewType;
  beforeStarting: boolean;
}

export interface MarkDiaglogOutput {
  reviewType: ReviewType;
  subQuestionSelection: SubQuestionSelection;
}
