import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AdminService } from "../../../services/admin/admin.service";
import { AuthService } from "../../../services/auth/auth.service";
import {
  ProfessionalBodyMapping,
  SlackChannels,
  SubjectData,
  SyllabusPdf,
  Tasks,
} from "../../../../../types/types";
import { environment } from "../../../../../types/environments/environment";
import { AngularFireStorage } from "@angular/fire/storage";
import { NotificationsService } from "../../../services/notifications/notifications.service";
@Component({
  selector: "app-subjects",
  templateUrl: "./subjects.component.html",
  styleUrls: ["./subjects.component.scss"],
})
export class AdminSubjectsComponent implements OnInit {
  professionalBodies = ProfessionalBodyMapping[environment.platform];
  subjectForm: FormGroup;

  constructor(
    public admin: AdminService,
    public auth: AuthService,
    private fb: FormBuilder,
    public storage: AngularFireStorage,
    public notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.subjectForm = this.fb.group({
      code: ["", Validators.required],
      name: ["", Validators.required],
      professionalBody: [null, Validators.required],
    });
  }

  goToSubject(subject) {
    this.admin.subject = subject;
    this.admin.router.navigate(["/admin/subjects/" + subject.id]);
  }

  addSubject() {
    const formResult = this.subjectForm.value;
    const isDuplicateCode = this.admin.subjects.some(
      (subject) => subject.code === formResult.code.toLowerCase()
    );
    if (this.subjectForm.valid && !isDuplicateCode) {
      const formResult = this.subjectForm.value;
      const newSubject: SubjectData = {
        active: false,
        name: formResult.name,
        code: formResult.code.toLowerCase(),
        codeDisplay: formResult.code,
        key: `${environment.platform}_${formResult.professionalBody}_${formResult.code}`,
        professionalBody: formResult.professionalBody,
        platform: environment.platform,
        rank: 1,
        creditsPerMark: 1,
        reviews: {
          actuary: false,
          ai: false,
        },
        send_notifications: false,
      };
      this.auth.fs
        .collection("subjects")
        .doc(newSubject.key)
        .set(newSubject)
        .then(() => {
          this.notifications.snack("Subject added successfully");
        })
        .catch((error) => {
          console.error("Error adding subject to collection: ", error);
        });
    }
  }
}
