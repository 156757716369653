import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import {
  Desktop,
  MultipleChoiceQuestion,
  MultipleChoiceQuestionAnswer,
  MultipleChoiceQuestionAttempt,
  SubjectData,
  Tasks,
  Topic,
} from "../../../../types/types";



enum State {
  loading = "loading",
  do = "do",
}

enum Loading {
  pageLoad = "Loading next question, hold on ...",
}

@Component({
  selector: "app-mcq",
  templateUrl: "./mcq.component.html",
  styleUrls: ["./mcq.component.scss"],
})
export class McqComponent implements OnInit {
  state: State = State.loading;
  desktopId: string;
  desktop: Desktop;
  toDo: MultipleChoiceQuestion[];
  loadingDescription: Loading = Loading.pageLoad;
  currentQuestion: MultipleChoiceQuestion | undefined;
  selectedAnswer: MultipleChoiceQuestionAnswer | undefined;
  showAnswer = false;
  subjectName = "";
  topicName = "";
  subTopicName = "";
  selectedOption: number | undefined;
  subject: SubjectData;
  public questionFlag = false;
  public questionFlagText = "";


  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public membershipService: MembershipService,
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.desktopId = this.getDesktopId(this.authService.router.url);
        this.authService.userInfo = await this.authService.getUserInfoOnce(
          user.uid
        );
        this.load();
      } else {
        this.authService.router.navigate(["/"]);
      }
      if (!membershipService.packageValid) {
        this.authService.router.navigate(["/account"]);
      }
    });
  }

  getDesktopId(url: string): string {
    return url.replace("/mcq/", "");
  }

  load() {
    this.authService.fs
      .collection("desktop")
      .doc(this.desktopId)
      .snapshotChanges()
      .subscribe(async (snapshot) => {
        if (snapshot.payload.exists) {
          this.desktop = snapshot.payload.data() as Desktop;
          if (this.desktop.focusTopicId && this.desktop.focusSubTopicId) {
            this.subjectName = await this.getSubjectName(
              this.desktop.subjectId
            );
            this.topicName = await this.getTopicName(this.desktop.focusTopicId);
            this.subTopicName = await this.getTopicName(
              this.desktop.focusSubTopicId
            );
            if (!this.currentQuestion) {
              await this.loadNextQuestion();
            }
          }
          this.state = State.do;
        } else {
          console.log("No payload");
          this.authService.router.navigate(["/"]);
        }
      });
  }

  loadNextQuestion(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (
        this.desktop.multipleChoiceQuestions &&
        this.desktop.multipleChoiceQuestions.length > 0
      ) {
        const questionId = this.desktop.multipleChoiceQuestions[0];
        if (this.desktop.multipleChoiceQuestions.length <= 2) {
          this.getNewRecommendations();
        }
        this.authService.fs
          .collection("multiple_choice")
          .doc(questionId)
          .get()
          .toPromise()
          .then((d) => {
            this.currentQuestion = d.data() as MultipleChoiceQuestion;
            // this.currentQuestion.rightAnswer =
            //   this.currentQuestion.answers.filter(
            //     (a) => a.correctChoice
            //   )[0].answer;
            resolve(true);
          })
          .catch((error) => {
            console.error("Error getting document:", error);
            reject(false);
          });
      }
    });
  }

  getSubjectName(subjectId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .doc(subjectId)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            const subjectData = doc.data() as SubjectData;
            this.subject = subjectData;
            resolve(subjectData.name);
          } else {
            reject(new Error("Subject not found"));
          }
        });
    });
  }

  getTopicName(topicId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("topics")
        .doc(topicId)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            const topic = doc.data() as Topic;
            resolve(topic.topic);
          } else {
            reject(new Error("Topic not found"));
          }
        });
    });
  }

  viewAnswer() {
    this.showAnswer = true;
    if (this.currentQuestion && this.selectedAnswer && this.selectedOption) {
      const attempt: MultipleChoiceQuestionAttempt = {
        multipleChoiceQuestionId: this.currentQuestion.id,
        proficiency: this.selectedAnswer.correctChoice,
        userId: this.desktop.userId,
        questionId: this.currentQuestion.questionId,
        timestamp: new Date().getTime(),
        subjectId: this.desktop.subjectId,
        selectedOption: this.selectedOption,
      };
      this.authService.fs.collection("multiple_choice_attempts").add(attempt);
    }
  }

  selectOption(selectedAnswer: MultipleChoiceQuestionAnswer) {
    this.selectedAnswer = selectedAnswer;
  }

  removeCurrentQuestionFromDesktop(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.currentQuestion && this.desktop.multipleChoiceQuestions) {
        const currentQuestionIndex =
          this.desktop.multipleChoiceQuestions.indexOf(this.currentQuestion.id);
        if (currentQuestionIndex > -1) {
          this.desktop.multipleChoiceQuestions.splice(currentQuestionIndex, 1);
          this.authService.fs
            .collection("desktop")
            .doc(this.desktopId)
            .update({
              multipleChoiceQuestions: this.desktop.multipleChoiceQuestions,
            })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              console.error("Error updating desktop: ", error);
              reject(error);
            });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  getNewRecommendations() {
    this.notificationsService.createTask(Tasks.generateAiRecommendation, {
      userId: this.desktop.userId,
      subjectId: this.desktop.subjectId,
    });
  }

  async next() {
    this.showAnswer = false;
    this.selectedAnswer = undefined;
    this.selectedOption = undefined;
    await this.removeCurrentQuestionFromDesktop();
    this.currentQuestion = undefined;
    await this.loadNextQuestion();
  }

  backToTopicSelection() {
    this.authService.goToDesktop(this.subject.code, "exam");
  }


  ngOnInit() { }
}
