import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material";
import { QuestionPart, ReviewType } from "../../../../types/types";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: "app-review-type-selected-topic",
  templateUrl: "./review-type-selected-topic.component.html",
  styleUrls: ["./review-type-selected-topic.component.scss"],
})
export class ReviewTypeSelectedTopicComponent {
  public subQuestionSelection: SubQuestionSelection = {
    selectedParts: [],
    marks: 0,
  };
  public ReviewType = ReviewType;
  public reviewType: ReviewType = ReviewType.self;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReviewTypeSelectedTopicDiaglogInput,
    private dialogRef: MatDialogRef<ReviewTypeSelectedTopicComponent>,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  get showActuaryOption(): boolean {
    return Boolean(this.data.acceptingActuaryReviews);
  }

  get showAIOption(): boolean {
    return Boolean(this.data.acceptingAiReviews);
  }

  get requiredCredits(): number {
    return this.reviewType === ReviewType.ai
      ? this.subQuestionSelection.marks * this.data.creditsPerMark * this.data.aiMarkingDiscountFactor
      : this.subQuestionSelection.marks * this.data.creditsPerMark;
  }

  get hasInsufficientCredits(): boolean {
    return this.data.credits - this.requiredCredits < 0;
  }

  get creditsPerMarkDisplay(): string {
    return this.reviewType === ReviewType.ai
      ? `${this.data.creditsPerMark * this.data.aiMarkingDiscountFactor} credit(s) per mark for AI marking`
      : `${this.data.creditsPerMark} credit(s) per mark`;
  }

  get canSubmitActuaryReview(): boolean {
    return Boolean(
      this.data.acceptingActuaryReviews &&
      this.subQuestionSelection.marks > 0 &&
      !this.hasInsufficientCredits
    );
  }

  get showSubQuestionSelection(): boolean {
    return this.reviewType === ReviewType.actuary;
  }

  close(cancel: boolean) {
    if (cancel) {
      this.reviewType = ReviewType.back;
    }
    const markDiaglogOutput: MarkDiaglogOutput = {
      reviewType: this.reviewType,
      subQuestionSelection: this.subQuestionSelection,
    };
    this.dialogRef.close(markDiaglogOutput);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  setSubQuestionSelection(subQuestionSelection: SubQuestionSelection) {
    this.subQuestionSelection = subQuestionSelection;
  }

  setReviewType(type: ReviewType) {
    this.reviewType = type;
  }
}

export interface ReviewTypeSelectedTopicDiaglogInput {
  subject: string;
  credits: number;
  creditsPerMark: number;
  aiMarkingDiscountFactor: number;
  subjectId: string;
  marks?: number;
  parts?: QuestionPart[];
  acceptingActuaryReviews?: boolean;
  acceptingAiReviews?: boolean;
  reviewType?: ReviewType;
  beforeStarting: boolean;
}

export interface MarkDiaglogOutput {
  reviewType?: ReviewType;
  subQuestionSelection: SubQuestionSelection;
}

interface SubQuestionSelection {
  selectedParts: string[];
  marks: number;
}
