import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SlackChannels, SubjectData } from "../../../../types/types"
import { NotificationsService } from '../../services/notifications/notifications.service';

interface FeatureInterest {
    [key: string]: boolean;
}

@Component({
    selector: 'app-study-mode-dialog',
    templateUrl: './study-mode-dialog.component.html',
    styleUrls: ['./study-mode-dialog.component.scss']
})
export class StudyModeDialogComponent implements OnInit {
    loadingInterest: { [key: string]: boolean } = {};
    hasExpressedInterest: { [key: string]: boolean } = {};

    constructor(
        public dialogRef: MatDialogRef<StudyModeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SubjectData,
        private notificationsService: NotificationsService,
        private snackBar: MatSnackBar
    ) {
        // Enable closing on backdrop click and escape key press
        dialogRef.disableClose = false;
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
        dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                dialogRef.close();
            }
        });
    }

    ngOnInit() {
        this.checkExistingInterest('mcq');
        this.checkExistingInterest('learn');
    }

    private async checkExistingInterest(mode: 'mcq' | 'exam' | 'learn') {
        this.loadingInterest[mode] = true;
        try {
            const docRef = this.notificationsService.fs.collection(`features`).doc(this.notificationsService.userDetails.uid);
            const doc = await docRef.get().toPromise();
            const data = doc.data() as FeatureInterest | undefined;
            if (data) {
                this.hasExpressedInterest[mode] = data[this.getKey(mode, this.data.codeDisplay)] === true;
            } else {
                this.hasExpressedInterest[mode] = false;
            }
        } catch (error) {
            console.error('Error checking interest:', error);
        } finally {
            this.loadingInterest[mode] = false;
        }
    }

    async selectMode(mode: 'mcq' | 'exam' | 'learn'): Promise<void> {
        this.dialogRef.close(mode);
        await this.notificationsService.postToSlack(
            `User selected ${mode.toUpperCase()} mode for ${this.data.codeDisplay}`,
            SlackChannels.contentSelectionDialog
        );
    }

    getKey(mode: 'mcq' | 'exam' | 'learn', subject: string): string {
        return `${subject}_${mode}`;
    }

    async expressInterest(mode: 'mcq' | 'exam' | 'learn'): Promise<void> {
        this.loadingInterest[mode] = true;
        try {
            const docRef = this.notificationsService.fs.doc(`features/${this.notificationsService.userDetails.uid}`);
            docRef.set({
                [this.getKey(mode, this.data.codeDisplay)]: true
            }, { merge: true });
            this.hasExpressedInterest[mode] = true;
            this.notificationsService.snack('Thanks for your interest! We\'ll notify you when this feature becomes available.');
            this.notificationsService.postToSlack(
                `User expressed interest in ${mode.toUpperCase()} mode for ${this.data.codeDisplay}`,
                SlackChannels.contentSelectionDialog
            );
        } catch (error) {
            console.error('Error expressing interest:', error);
            this.notificationsService.snack('Sorry, there was an error recording your interest. Please try again.');
        } finally {
            this.loadingInterest[mode] = false;
        }
    }
} 