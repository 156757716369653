import { environment as env } from "./act.dev";
import { stage } from "../types";

export const environment = {
  ...env,
  envName: stage.PROD,
  firebase: {
    apiKey: "AIzaSyCwVZ91-VH7rCDJhgTzcA6_A0ExNLAG2VU",
    authDomain: "thinkactuary.com",
    databaseURL: "https://thinkactuarylearn.firebaseio.com",
    projectId: "thinkactuarylearn",
    storageBucket: "thinkactuarylearn.appspot.com",
    messagingSenderId: "909732792696",
    appId: "1:909732792696:web:0161224cfcb17e6649e08d",
    measurementId: "G-4ETCN6GW9W",
  },
  subjects: [
    {
      code: "A311",
      name: "Actuarial Risk Management",
      questions: "More than 280",
      multipleChoiceQuestions: "More than 2300",
      reviews: "More than 8300",
      professionalBody: "ASSA",
    },
    {
      code: "F102",
      name: "Life Insurance Principles",
      questions: "More than 150",
      reviews: "More than 3800",
      professionalBody: "ASSA",
    },
    {
      code: "F103",
      name: "General Insurance Principles",
      questions: "More than 150",
      reviews: "More than 1500",
      professionalBody: "ASSA",
    },
    {
      code: "F105",
      name: "Finance and Investment Principles",
      questions: "More than 170",
      reviews: "More than 1100",
      professionalBody: "ASSA",
    },
    {
      code: "F106",
      name: "Enterprise Risk Management",
      questions: "More than 60",
      reviews: "More than 130",
      professionalBody: "ASSA",
    },
    {
      code: "F108",
      name: "Health, Social and Employee Benefits Fellowship Principles",
      questions: "More than 170",
      professionalBody: "ASSA",
    },
    {
      code: "F201",
      name: "Health and Care Specialist Applications",
      questions: "More than 50",
      reviews: "More than 150",
      comingSoon: false,
      professionalBody: "ASSA",
    },
    {
      code: "F202",
      name: "Life Insurance Fellowship Applications",
      questions: "More than 60",
      reviews: "More than 1600",
      professionalBody: "ASSA",
    },
    {
      code: "F203",
      name: "General Insurance Fellowship Applications",
      questions: "More than 40",
      reviews: "More than 200",
      professionalBody: "ASSA",
    },
  ],
};
