import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import { DataServiceService } from "../../services/data/data-service.service";
import {
  ReviewType,
  QuestionData,
  SubjectData,
  SlackChannels,
  ExamReview,
  Tasks,
  Topic,
  Desktop,
  MultipleChoiceQuestion,
  MultipleChoiceQuestionAnswer,
  MultipleChoiceQuestionAttempt,
} from "../../../../types/types";
import { SubQuestionSelection } from "../../components/sub-question-selection/sub-question-selection.component";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { AdvertiserPopupComponent } from "../../dialogs/advertiser/advertiser.component";
import { AdminService } from "../../services/admin/admin.service";
import * as ClassicEditor from "../../ckeditor5";

enum State {
  loading = "loading",
  selectTopic = "selectTopic",
  recommendationLoading = "recommendationLoading",
  doQuestions = "doQuestions",
}

enum Loading {
  subject = "Loading multiple choice questions, hang tight ...",
  question = "Loading question ...",
}

@Component({
  selector: "app-desktop-mcq",
  templateUrl: "./desktop-mcq.component.html",
  styleUrls: ["./desktop-mcq.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DesktopMCQComponent {
  @ViewChild("top") subjectsHTML: ElementRef;

  public state: State = State.loading;
  public loadingDescription: Loading = Loading.subject;
  public subjectCode = "";
  public subjectKey: string;
  public subjectData: SubjectData;
  public desktop: Desktop;
  public topics: Topic[] = [];
  public topicsLevel1: Topic[] = [];
  public allTopicsLevel2: Topic[] = [];
  public allTopicsLevel2Dict: { [key: string]: Topic[] } = {};
  public topicsLevel2: Topic[] = [];
  public selectedTopicL1: Topic | undefined;
  public selectedTopicL2: Topic | undefined;
  public questionFlag = false;
  public questionFlagText = "";
  public Editor = ClassicEditor;
  public subjectCodeDisplay = "";
  public subjectName = "";
  public selectedQuestionKey: string | undefined;
  public desktopType: string;

  currentQuestion: MultipleChoiceQuestion | undefined;
  selectedAnswer: MultipleChoiceQuestionAnswer | undefined;
  showAnswer = false;
  topicName = "";
  subTopicName = "";
  selectedOption: number | undefined;
  subject: SubjectData;

  public mcqsToDo: MultipleChoiceQuestion[];
  public mcqData: MultipleChoiceQuestion;
  public recentAttempts: Array<{
    attempt: MultipleChoiceQuestionAttempt;
    question: MultipleChoiceQuestion;
    selectedAnswer: MultipleChoiceQuestionAnswer;
    correctAnswer?: MultipleChoiceQuestionAnswer;
  }> = [];
  public completionRate = 0;
  public correctAnswerRate = 0;
  private attemptsSubscription: any;
  private selectedTopicAnalytics: any;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private membershipService: MembershipService,
    private dataService: DataServiceService,
    public analyticsService: AnalyticsService,
    private adminService: AdminService,
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.subjectCode = this.getSubjectCode(this.authService.router.url);
        this.desktopType = this.getDesktopType(this.authService.router.url);
        this.setCurrentSubjectCode(this.subjectCode, user.uid);
        const validSubject = await this.authService.validateSubjectKey(
          this.subjectCode
        );
        if (validSubject) {
          this.authService.userInfo = await this.authService.getUserInfoOnce(
            user.uid
          );
          if (!this.authService.userInfo["tcsAccepted"]) {
            this.authService.termsAcceptedCheck();
          }
          this.notificationsService.postToSlack(
            "desktop-mcq",
            SlackChannels.pages
          );
          this.loadSubjectData();
          this.subjectKey = await this.dataService.getSubjectKeyFromCode(
            this.subjectCode
          );
          await this.dataService.loadSubjectData(this.subjectKey);
          this.loadDesktop();
          this.loadRecentAttempts();
        } else {
          this.authService.router.navigate(["/subjects"]);
        }
        if (!membershipService.packageValid) {
          this.authService.router.navigate(["/account"]);
        }
      }
    });
  }

  getSubjectCode(url: string): string {
    const parts = url.split("/");
    return parts[2]; // Assuming the URL format is always /desktop/xxx/exam
  }

  getDesktopType(url: string): string {
    const parts = url.split("/");
    return parts[3]; // Assuming the URL format is always /desktop/xxx/exam
  }

  loadSubjectData() {
    this.authService.fs
      .collection("subjects", (ref) =>
        ref.where("code", "==", this.subjectCode)
      )
      .get()
      .subscribe((data) => {
        data.forEach(async (d) => {
          this.subjectData = d.data() as SubjectData;
          this.subjectCodeDisplay = this.subjectData["codeDisplay"];
          this.subjectName = this.subjectData["name"];
          await this.loadRecentAttempts(); // Load attempts after subject data is loaded
        });
      });
  }

  setCurrentSubjectCode(currentSubjectCode, userId) {
    this.authService.fs
      .collection("users")
      .doc(userId)
      .update({ currentSubjectCode });
  }

  loadDesktop() {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    const desktopType = this.getDesktopType(this.authService.router.url);
    this.authService.fs
      .collection("desktop_mcq")
      .doc(`${userId}_${subjectId}`)
      .snapshotChanges()
      .subscribe((snapshot) => {
        if (snapshot.payload.exists) {
          this.desktop = snapshot.payload.data() as Desktop;
          if (this.desktop.analytics) {
            if (
              !this.topicsLevel1 ||
              JSON.stringify(this.desktop.analytics.topicCoverageLevel1) !==
              JSON.stringify(this.topicsLevel1)
            ) {
              this.topicsLevel1 = this.desktop.analytics
                .topicCoverageLevel1 as Topic[];
            }
            this.allTopicsLevel2 = this.desktop.analytics
              .topicCoverageLevel2 as Topic[];
            this.topicsLevel1.forEach((t) => {
              this.allTopicsLevel2Dict[t.key] = this.allTopicsLevel2.filter(
                // @ts-ignore
                (t2) => t2.key.includes(t.key)
              );
            });
            if (this.state === State.loading) {
              this.state = State.selectTopic;
            }
            if (
              this.desktop.analytics.lastQuestionTimestamp !== null &&
              this.desktop.focusTopicId &&
              this.desktop.focusSubTopicId
            ) {
              const topicText = this.allTopicsLevel2.filter(
                // @ts-ignore
                (t2) => t2.key.includes(this.desktop.focusSubTopicId)
              )[0].topic;
              this.selectedTopicAnalytics = this.desktop.analytics.topicCoverageLevel2.find(t => t.key == this.desktop.focusSubTopicId);
            }
          }
          if (this.desktop.focusTopicId && this.desktop.focusSubTopicId) {
            this.filterSubtopics();
          }
        } else {
          console.log("No payload");
          this.notificationsService.createTask(Tasks.refreshMCQAnalytics, {
            userId,
            subjectId,
            desktopType,
          });
        }
      });
  }

  filterSubtopics() {
    if (this.desktop.focusTopicId) {
      this.topicsLevel2 = this.allTopicsLevel2.filter(
        // @ts-ignore
        (t) => t.level == 2 && t.key.includes(this.desktop.focusTopicId)
      );
    }
  }

  async setFocusSubTopicId(focusTopicId, focusSubTopicId) {
    // Show loading immediately
    this.loadingDescription = Loading.question;
    this.state = State.loading;

    // Reset state
    this.selectedAnswer = undefined;
    this.showAnswer = false;
    this.selectedOption = undefined;
    this.currentQuestion = undefined;

    try {


      // Get questions for this topic
      this.mcqsToDo = this.dataService.allSubjectMCQs.filter(
        q => q.selectedTopicId === focusSubTopicId
      );

      if (!this.mcqsToDo || this.mcqsToDo.length === 0) {
        throw new Error('No questions available for this topic');
      }

      // Simple shuffle
      for (let i = this.mcqsToDo.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.mcqsToDo[i], this.mcqsToDo[j]] = [this.mcqsToDo[j], this.mcqsToDo[i]];
      }

      // Load first question
      this.currentQuestion = this.mcqsToDo[0];
      this.mcqData = this.mcqsToDo[0];
      this.selectedQuestionKey = this.mcqsToDo[0].key;
      this.state = State.doQuestions;

      // Update Firestore if topic changed
      if (this.desktop.focusSubTopicId !== focusSubTopicId || this.desktop.focusTopicId !== focusTopicId) {
        await this.authService.fs
          .collection("desktop_mcq")
          .doc(`${this.authService.userInfo.uid}_${this.subjectKey}`)
          .update({ focusTopicId, focusSubTopicId });
      }

    } catch (error) {
      console.error('Error loading questions:', error);
      this.notificationsService.snack(error.message || 'Error loading questions. Please try again.');
      this.state = State.selectTopic;
    }
  }

  async nextMCQ() {
    this.loadingDescription = Loading.question;
    this.state = State.loading;

    try {
      let index = 0;
      if (this.selectedQuestionKey) {
        index = this.mcqsToDo.findIndex((q) => q.key === this.selectedQuestionKey) + 1;
        if (index >= this.mcqsToDo.length) {
          index = 0;
        }
      }

      this.currentQuestion = this.mcqsToDo[index];
      this.mcqData = this.mcqsToDo[index];
      this.selectedQuestionKey = this.mcqsToDo[index].key;
      this.state = State.doQuestions;
    } catch (error) {
      console.error('Error loading next question:', error);
      this.notificationsService.snack('Error loading next question. Please try again.');
    }
  }

  selectOption(selectedAnswer: MultipleChoiceQuestionAnswer) {
    this.selectedAnswer = selectedAnswer;
  }

  viewAnswer() {
    this.showAnswer = true;
    if (this.currentQuestion && this.selectedOption !== undefined && this.selectedAnswer) {
      const attempt: MultipleChoiceQuestionAttempt = {
        multipleChoiceQuestionId: this.currentQuestion.id,
        proficiency: this.selectedAnswer.correctChoice,
        userId: this.desktop.userId,
        questionId: this.currentQuestion.questionId,
        timestamp: new Date().getTime(),
        subjectId: this.subjectKey,
        selectedOption: this.selectedOption,
      };
      this.authService.fs
        .collection("multiple_choice_attempts")
        .add(attempt)
        .then((d) => {
          this.notificationsService.createTask(Tasks.refreshMCQAnalytics, {
            userId: this.desktop.userId,
            subjectId: this.subjectKey,
          });
          // No need to manually reload attempts as the subscription will handle it
        });
    }
  }

  next() {
    this.showAnswer = false;
    this.selectedAnswer = undefined;
    this.selectedOption = undefined;
    let nextIndex = this.mcqsToDo.indexOf(this.currentQuestion!) + 1;
    if (nextIndex < this.mcqsToDo.length) {
      this.currentQuestion = this.mcqsToDo[nextIndex];
      this.mcqData = this.mcqsToDo[nextIndex];
      this.selectedQuestionKey = this.mcqsToDo[nextIndex].key;
      this.state = State.doQuestions;
    }
  }

  getCurrentQuestionNumber(): number {
    if (!this.currentQuestion || !this.mcqsToDo) return 0;
    return this.mcqsToDo.indexOf(this.currentQuestion) + 1;
  }

  flagQuestion() {
    if (this.currentQuestion) {
      this.notificationsService.snack(
        "Thanks for letting us know. We will look into this, and may contact you to better understand the issue."
      );
      this.notificationsService.postToSlack(
        `Issue report on question ${this.currentQuestion.id} - ${this.questionFlagText}`,
        SlackChannels.mcqIssues
      );
      this.questionFlag = false;
      this.questionFlagText = "";
    }
  }

  loadRecentAttempts() {
    if (!this.subjectKey) return;

    // Unsubscribe from any existing subscription
    if (this.attemptsSubscription) {
      this.attemptsSubscription.unsubscribe();
    }

    // Create a new subscription
    this.attemptsSubscription = this.authService.fs
      .collection("multiple_choice_attempts", ref =>
        ref.where("userId", "==", this.authService.userInfo.uid)
          .where("subjectId", "==", this.subjectKey)
          .orderBy("timestamp", "desc")
          .limit(50))
      .valueChanges()
      .subscribe(a => {
        if (!a) return;
        const attempts = a as MultipleChoiceQuestionAttempt[];
        this.recentAttempts = [];
        attempts.forEach(attempt => {
          const question = this.dataService.allSubjectMCQs.find(q => q.id === attempt.multipleChoiceQuestionId);
          if (question) {
            const selectedAnswer = question.answers[attempt.selectedOption];
            const correctAnswer = question.answers.find(a => a.correctChoice);
            if (correctAnswer && selectedAnswer) {
              this.recentAttempts.push({
                attempt,
                question,
                selectedAnswer,
                correctAnswer
              });
            }
          }
        });
      });
  }

  ngOnDestroy() {
    if (this.attemptsSubscription) {
      this.attemptsSubscription.unsubscribe();
    }
  }

  scroll() {
    try {
      this.subjectsHTML.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (e) {
      console.log(e);
    }
  }

  backToMenu() {
    this.notificationsService
      .snackConfirm("Go back?", 7500)
      .onAction()
      .subscribe(() => {
        this.state = State.selectTopic;
        this.scroll();
      });
  }
  ß
}
