import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import * as firebase from "firebase/app";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AuthService } from "../../services/auth/auth.service";
import { CreditInstructionsComponent } from "../../dialogs/credit-instructions/credit-instructions.component";
import { MarkConfirmComponent } from "../../dialogs/mark-confirm/mark-confirm.component";
import { ReviewTypeSelectedTopicComponent } from "../../dialogs/review-type-selected-topic/review-type-selected-topic.component";
import { ExamPressureEndComponent } from "../../dialogs/exam-pressure-end/exam-pressure-end.component";
import { ExamPressureSubmitComponent } from "../../dialogs/exam-pressure-submit/exam-pressure-submit.component";
import * as ClassicEditor from "../../ckeditor5";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import { AdminService } from "../../services/admin/admin.service";
import { DataServiceService } from "../../services/data/data-service.service";
import {
  ReviewType,
  TopicNode,
  QuestionData,
  SubjectData,
  SlackChannels,
  Review,
  ExamReview,
  ExamStates,
  ExamQuestionReview,
  User,
  ProfessionalBodies,
  ProfessionalBodyDescriptions,
} from "../../../../types/types";
import {
  ReviewTypeExamDiaglogInput,
  ReviewTypeExamDiaglogOutput,
  ReviewTypeExamComponent,
} from "../../dialogs/review-type-exam/review-type-exam.component";
import { SubQuestionSelection } from "../../components/sub-question-selection/sub-question-selection.component";

@Component({
  selector: "app-exam-env",
  templateUrl: "./exam-env.component.html",
  styleUrls: ["./exam-env.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExamEnvComponent {
  @ViewChild("top") subjectsHTML: ElementRef;
  @ViewChild("editor") editorComponent: any;

  public Editor = ClassicEditor;
  public editor: any;
  public subjectCode = "";
  public subjectData: SubjectData;
  public paperRef = "";
  public loading = true;
  public saving = false;
  public showInstructions = true;
  public categories = [];
  public topics = [];
  public categoryQuestionCount = [];
  public userProgress = [];
  public currentTopic = "";
  public currentCategory = "";
  public emptyCategory = true;
  public currentTopicNode: TopicNode;
  public examQuestionData: QuestionData[];
  public questionRef = "";

  // Question and Memo section
  public source = null;
  public questionCount = 0;
  public question_html = null;
  public memo_html = null;
  public activeTab = "attempt_tab";
  public questionAttempt = 0;
  public secsBeforeFeedback = 60;
  public topicSelectedQuestion = false;
  public busySaving = false;
  public subjectCodeDisplay = "";
  public subjectName = "";
  public examPressureOn = false;
  public examPressureReviewType: ReviewType;
  public selectedQuestionIndex = 0;
  public submittedForMarking = false;
  public subQuestionSelection: SubQuestionSelection;
  public toBePeerReviewed = false;
  public canRequestPeerReview = false;
  public canEdit = false;
  public loadingLock = Symbol();

  // question issues
  public questionFlag = false;
  public questionFlagText = "";

  // Attempt text
  public attemptText = null;
  public attempted = false;
  public credits = 0;
  public reviews = [];

  // Timer section
  public timeLeftThreshold = 0;
  public questionAttempted = false;
  public interval;
  public min_remaining = 0;
  public sec_remaining = 0;
  public hrs_remaining = 0;
  public time = 0;
  public secondsPerMark = 1.8 * 60;
  public secondsReadingTime = 15 * 60;
  public acceptingActuaryReviews = false;
  public acceptingPeerReviews = false;

  // Exam section
  public examAttempt: ExamReview = {
    // @ts-ignore
    reviews: [{ attemptText: "" }],
  };
  public examId: string;
  public questionIndex = 0;
  public examStarted = false;
  public examDataLoaded = false;
  private professionalBodyDescriptions = {
    [ProfessionalBodies.ASSA]: ProfessionalBodyDescriptions.ASSA,
    [ProfessionalBodies.IFOA]: ProfessionalBodyDescriptions.IFOA,
  };

  public monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private membershipService: MembershipService,
    private adminService: AdminService,
    private dataService: DataServiceService
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.examId = this.getExamId(this.authService.router.url);
        this.examAttempt = (await this.dataService.loadExamAttempt(
          this.examId
        )) as ExamReview;
        this.authService.userInfo = await this.authService.getUserInfoOnce(
          user.uid
        );
        this.notificationsService.postToSlack("exam-env", SlackChannels.pages);
        await this.loadUserCredits();
        await this.loadSubjectData(this.examAttempt.subjectId);
        if (this.examAttempt.marksPossible) {
        } else {
          this.examAttempt = this.createExamAttemptObject();
          await this.dataService.updateExamAttempt(
            this.examAttempt,
            this.examId
          );
        }
        this.manageExam();
        if (this.editor) {
          this.editor.setData(this.examAttempt.reviews[0].attemptText);
        }
        this.loading = false;
      }
    });
  }

  getExamId(url: string): string {
    return url.replace("/exam/", "");
  }

  loadSubjectData(subjectId: string) {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .doc(subjectId)
        .get()
        .subscribe(async (data) => {
          this.subjectData = data.data() as SubjectData;
          this.subjectCodeDisplay = this.subjectData.codeDisplay;
          this.subjectCode = this.subjectData.code;
          this.subjectName = this.subjectData.name;
          this.acceptingActuaryReviews = this.subjectData.reviews.actuary;
          this.acceptingPeerReviews = false;
          this.canRequestPeerReview = await this.authService.peerReviews(
            this.authService.userInfo.uid,
            this.subjectCodeDisplay.toUpperCase()
          );
          resolve(true);
        });
    });
  }

  loadUserCredits() {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("users")
        .doc(this.authService.userInfo["uid"])
        .valueChanges()
        .subscribe((data) => {
          const user = data as User;
          this.credits = user.credits;
          resolve(true);
        });
    });
  }

  setTimer() {
    let remaining = this.examAttempt.timeTotal;
    if (this.examAttempt.timestampStarted) {
      const now = new Date().getTime();
      remaining = Math.max(
        this.examAttempt.timeTotal -
        (now - this.examAttempt.timestampStarted) / 1000,
        0
      );
    }
    this.hrs_remaining = Math.floor(remaining / 3600);
    this.min_remaining = Math.floor(
      (remaining - this.hrs_remaining * 3600) / 60
    );
    this.sec_remaining = Math.floor(
      remaining - this.min_remaining * 60 - this.hrs_remaining * 3600
    );
  }

  async startExam() {
    if (this.examAttempt.state === ExamStates.NON_STARTED) {
      this.notificationsService
        .snackConfirm(`Are you sure you want to start the exam?`, 5000)
        .onAction()
        .subscribe(async () => {
          this.examAttempt.state = ExamStates.IN_PROGRESS;
          this.examAttempt.timestampStarted = new Date().getTime();
          await this.dataService.updateExamAttempt(
            this.examAttempt,
            this.examId
          );
          this.manageExam();
        });
    }
  }

  editorReadOnly(readOnly) {
    if (this.editor) {
      if (readOnly) {
        this.editor.enableReadOnlyMode(this.loadingLock);
      } else {
        this.editor.disableReadOnlyMode(this.loadingLock);
      }
    }
  }

  async manageExam() {
    if (this.examAttempt.state === ExamStates.SUBMITTED_FOR_REVIEW) {
      this.editorReadOnly(true);
      clearInterval(this.interval);
      this.notificationsService.snack(
        "Your attempt at this paper has been submitted for review."
      );
      this.showInstructions = false;
    } else if (this.examAttempt.state === ExamStates.COMPLETED) {
      this.editorReadOnly(true);
      clearInterval(this.interval);
      this.submitForReview();
      this.showInstructions = false;
    } else if (this.examAttempt.state === ExamStates.IN_PROGRESS) {
      this.editorReadOnly(false);
      this.examStarted = true;
      this.showInstructions = false;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        const now = new Date().getTime();
        const total = this.examAttempt.timeTotal
          ? this.examAttempt.timeTotal
          : 0;
        const started = this.examAttempt.timestampStarted
          ? this.examAttempt.timestampStarted
          : 0;
        this.examAttempt.timeLeft = total - (now - started) / 1000;
        if (this.examAttempt.timeLeft >= 0) {
          this.hrs_remaining = Math.floor(this.examAttempt.timeLeft / 3600);
          this.min_remaining = Math.floor(
            (this.examAttempt.timeLeft - this.hrs_remaining * 3600) / 60
          );
          this.sec_remaining = Math.floor(
            this.examAttempt.timeLeft -
            this.min_remaining * 60 -
            this.hrs_remaining * 3600
          );
        } else {
          if (this.examAttempt.state === ExamStates.IN_PROGRESS) {
            this.notificationsService.snack("Your time has run out.");
            this.finishExam();
          }
          this.hrs_remaining = 0;
          this.min_remaining = 0;
          this.sec_remaining = 0;
        }
      }, 1000);
    } else if (this.examAttempt.state === ExamStates.NON_STARTED) {
      this.editorReadOnly(true);
      this.setTimer();
    }
  }

  async finishExam() {
    if (this.examAttempt.state === ExamStates.IN_PROGRESS) {
      clearInterval(this.interval);
      this.notificationsService.snack("Exam completed.");
      this.examAttempt.state = ExamStates.COMPLETED;
      this.examAttempt.timestampFinished = new Date().getTime();
      this.editorReadOnly(true);
      await this.dataService.updateExamAttempt(this.examAttempt, this.examId);
      this.submitForReview();
    }
  }

  updateExamState(state: ExamStates) {
    return new Promise(async (resolve, reject) => {
      this.examAttempt.state = state;
      await this.dataService.updateExamAttempt(this.examAttempt, this.examId);
      resolve(true);
    });
  }

  saveAttemptTextData(questionKey: string, attemptText: string) {
    this.authService.fs
      .collection("attempts")
      .doc(`${this.authService.userInfo.uid}_${questionKey}`)
      .set({
        attemptText,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        uid: this.authService.userInfo.uid,
        questionKey: questionKey,
      });
  }

  saveAttemptText(userInitiated: boolean = false) {
    this.busySaving = true;
    if (userInitiated) {
      this.notificationsService.postToSlack(
        "Question saved",
        SlackChannels.analytics
      );
    }
    if (this.attemptText == null || this.attemptText === "") {
      console.log("Nothing saved, no answer");
    } else {
      this.saveAttemptTextData(
        this.examQuestionData[this.selectedQuestionIndex].key,
        this.attemptText
      );
    }
  }

  sortByKey(array: Review[], key: string) {
    return array.sort(function (a, b) {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  createExamAttemptObject(): ExamReview {
    const questionList: ExamQuestionReview[] =
      this.dataService.allSubjectQuestions
        .filter((q) => q.paperId === this.examAttempt.paperId)
        .map((q) => this.createQuestionAttemptObject(ReviewType.pending, q));
    const reviews = this.sortByKey(questionList, "questionNumber");
    const marks = 100;
    const minsPerMark = 1.8;
    const readingTimeMins = 15;
    const timeTotal = (marks * minsPerMark + readingTimeMins) * 60;
    const examData: ExamReview = {
      ...this.examAttempt,
      id: this.examId,
      email: this.authService.userInfo["email"],
      user: this.authService.userInfo["uid"],
      timestampSubmit: -1,
      timestampCreated: new Date().getTime(),
      timestampSaved: new Date().getTime(),
      state: ExamStates.NON_STARTED,
      marksPossible: marks,
      reviewType: ReviewType.pending,
      reviews,
      timeTotal,
      timeLeft: timeTotal,
    };
    return examData;
  }

  createQuestionAttemptObject(
    reviewType: ReviewType,
    question: QuestionData
  ): ExamQuestionReview {
    const marks = question.marks;
    const partsToBeMarked = question.parts;
    const partsToBeMarkedCleaned = partsToBeMarked.map((part) => {
      let newPart = part;
      delete newPart.question_html;
      delete newPart.memo_html;
      return newPart;
    });
    const questionTrailer = this.dataService.getQuestionPreview(
      partsToBeMarkedCleaned,
      75
    );
    let creditsUsed = 0;
    creditsUsed = this.subjectData.creditsPerMark * marks;
    const examQuestionReview: ExamQuestionReview = {
      user: this.authService.userInfo["uid"],
      email: this.authService.userInfo["email"],
      subject: this.subjectCode,
      subjectId: this.subjectData.key,
      subjectName: this.subjectName,
      mark: marks,
      creditsUsed: creditsUsed,
      source: question.source,
      partsToBeMarked: partsToBeMarkedCleaned,
      question_identifier: question.question_string,
      questionKey: question.key,
      attemptText: "",
      reviewed: false,
      state: 0,
      timestamp_submit: -1,
      time_submitted: -1,
      reviewed_by: false,
      reviewer_comments_html: "",
      email_underway: false,
      email_completed: false,
      score: 0,
      score_mark: 0,
      reviewType,
      feedback_quality: "0",
      questionTrailer,
      questionNumber: question.number,
      structure_score: {
        key_ideas: 0,
        info_used: 0,
        concise: 0,
        idea_generated: 0,
      },
      examReviewId: this.examId,
    };

    return examQuestionReview;
  }

  selectQuestion(index) {
    this.questionIndex = index;
    this.startExam();
  }

  saveAttempts() {
    this.dataService
      .updateExamAttempt(this.examAttempt, this.examId)
      .then((success) => {
        this.notificationsService.snack("Data saved", 10000);
      });
  }

  scroll() {
    try {
      this.subjectsHTML.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (e) {
      console.log(e);
    }
  }

  imDone() {
    this.notificationsService
      .snackConfirm(`Are you sure you want to finish the exam?`, 5000)
      .onAction()
      .subscribe(() => {
        this.finishExam();
      });
  }

  submitForReview() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "800px";
    const data: ReviewTypeExamDiaglogInput = {
      subject: this.subjectName,
      credits: this.credits,
      creditsPerMark: this.subjectData.creditsPerMark,
      subjectId: this.subjectData.key,
      acceptingActuaryReviews: this.acceptingActuaryReviews,
      acceptingPeerReviews: this.acceptingPeerReviews,
      tuitionCourseParticipant: this.authService.tuitionCourseParticipant,
      beforeStarting: false,
      exam: this.examAttempt,
      marks: this.examAttempt.marksPossible,
    };

    dialogConfig.data = data;
    this.dialog
      .open(ReviewTypeExamComponent, dialogConfig)
      .afterClosed()
      .subscribe((data) => {
        data as ReviewTypeExamDiaglogOutput;
        if (data.reviewType !== ReviewType.back) {
          this.markExamAttempt(data.reviewType);
        }
      });
  }

  saveAttemptForMarking(attemptObject: Review) {
    return new Promise<string>((resolve, reject) => {
      this.authService.fs
        .collection("reviews")
        .add(attemptObject)
        .then((d) => {
          if (attemptObject.reviewType == ReviewType.actuary) {
            this.notificationsService.postToSlack(
              "Exam question submitted for actuary review",
              SlackChannels.reviews
            );
          } else {
            this.notificationsService.postToSlack(
              "Exam question submitted for self review",
              SlackChannels.reviews
            );
          }

          resolve(d.id);
        })
        .catch((e) => {
          reject(true);
        });
    });
  }

  async submitQuestionsForReview(
    examAttempt: ExamReview,
    reviewType: ReviewType
  ) {
    return new Promise<ExamReview>(async (resolve, reject) => {
      examAttempt.timestampSubmit = new Date().getTime();
      examAttempt.reviewType = reviewType;
      await this.dataService.updateExamAttempt(examAttempt, examAttempt.id);
      for (const review of examAttempt.reviews) {
        const index = examAttempt.reviews.indexOf(review);
        if (!review.reviewCollectionKey) {
          review.reviewType = reviewType;
          review.time_submitted = new Date().getTime();
          review.timestamp_submit = new Date().getTime();
          const reviewCollectionKey = await this.saveAttemptForMarking(review);
          review.reviewCollectionKey = reviewCollectionKey;
          examAttempt.reviews[index] = review;
          await this.dataService.updateExamAttemptReviews(
            { reviews: examAttempt.reviews },
            examAttempt.id
          );
        }
      }
      resolve(examAttempt);
    });
  }

  async markExamAttempt(reviewType: ReviewType) {
    if (reviewType === ReviewType.actuary) {
      this.saving = true;
      const creditsForExam =
        this.examAttempt.marksPossible * this.subjectData.creditsPerMark;
      if (creditsForExam <= this.credits) {
        await this.submitQuestionsForReview(this.examAttempt, reviewType);
        await this.membershipService.changeCredits(
          -1 * creditsForExam,
          "Marking"
        );
        await this.updateExamState(ExamStates.SUBMITTED_FOR_REVIEW);
        this.authService.router.navigate(["/desktop/" + this.subjectCode]);
        this.notificationsService.snack(
          "Your exam questions has been sent for review and they should be marked within 72 hours. You will receive an email once each question has been marked."
        );
      } else {
        this.notificationsService.snack(
          "You do not have enough marking credits. You can acquire more and then return to this page to submit for actuary review."
        );
      }
      this.saving = false;
    }

    if (reviewType === ReviewType.self) {
      this.saving = true;
      this.examAttempt = await this.submitQuestionsForReview(
        this.examAttempt,
        reviewType
      );
      await this.updateExamState(ExamStates.SUBMITTED_FOR_REVIEW);
      this.notificationsService.postToSlack(
        "Self-mark exam completed",
        SlackChannels.reviews
      );
      this.authService.router.navigate(["/desktop/" + this.subjectCode]);
      this.notificationsService.snack(
        "Each of your attempts are listed below. Please select them individually to perform a self-review."
      );
      this.saving = false;
    }
  }

  leaveExam() {
    this.notificationsService
      .snackConfirm(
        `Are you sure you want to leave the exam? All progress will be lost forever.`,
        5000
      )
      .onAction()
      .subscribe(() => {
        this.dataService
          .updateExamAttempt({ state: ExamStates.STOPPED }, this.examId)
          .then((success) => {
            this.authService.goToDesktop(this.subjectCode, "exam");
          });
      });
  }

  onReady(editor) {
    if (editor) {
      this.editor = editor;
      this.editorReadOnly(true);
      this.manageExam();
      if (this.examAttempt.reviews[0].attemptText !== "") {
        this.editor.setData(this.examAttempt.reviews[0].attemptText);
      }
    }
  }
}
