import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireDatabase } from "@angular/fire/database";
import { DomSanitizer } from "@angular/platform-browser";
import {
  FlatNode,
  QuestionData,
  ExamReadiness,
  Review,
  ExamReview,
  ExamStates,
  SelectExamOutput,
  SubjectData,
  Assignment,
  AttemptText,
  Paper,
  Podcast,
  MultipleChoiceQuestion,
  AiConfig,
  Fees,
} from "../../../../types/types";

@Injectable({
  providedIn: "root",
})
export class DataServiceService {
  public currentSubjectKey = "";
  public currentSubjectCode = "";
  public questionsComplete: string[] = [];
  public allQuestionRefs = [];
  public questionRef = "";
  public papers: Paper[] = [];
  public questionList: QuestionData[] = [];
  public userAttempts: AttemptText[] = [];
  public examAttemptsUnfinished: ExamReview[] = [];
  public reviews: Review[] = [];
  public examReadiness: ExamReadiness;
  public allSubjectQuestions: QuestionData[];
  public allSubjectMCQs: MultipleChoiceQuestion[];
  private reviewsCollection: any;
  public subjectsList: SubjectData[] = [];

  constructor(
    public authService: AuthService,
    public afs: AngularFirestore,
    public db: AngularFireDatabase,
    private sanitizer: DomSanitizer,
    private analyticsService: AnalyticsService
  ) { }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getUsersLastQuestionsSubject() {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("reviews", (ref) =>
          ref
            .where("user", "==", this.authService.userDetails.uid)
            .orderBy("time_submitted", "asc")
            .limitToLast(1)
        )
        .get()
        .subscribe((reviews) => {
          if (reviews.empty) {
            resolve(null);
          } else {
            reviews.forEach((review) => {
              resolve(review.data()["subject"]);
            });
          }
        });
    });
  }

  featuredSubject(data: SubjectData, featured: boolean) {
    data.featured = featured;
    // Activate all subjects when in dev mode
    if (this.authService.dev) {
      data.active = true;
    }
    return data;
  }

  async loadSubjects(uid: string) {
    return new Promise(async (resolve, reject) => {
      const subjectCode = await this.getUsersLastQuestionsSubject();
      const userData = await this.authService.getUserInfoOnce(uid);
      if (userData.professionalBody) {
        this.authService.fs
          .collection("subjects")
          .get()
          .subscribe((subjects) => {
            this.subjectsList = [];
            subjects.forEach((subject) => {
              const s = subject.data() as SubjectData;
              s.id = subject.id;
              if (
                s["professionalBody"] == userData.professionalBody &&
                s["active"]
              ) {
                if (subject.data()["code"] !== subjectCode) {
                  this.subjectsList.push(this.featuredSubject(s, false));
                } else {
                  this.subjectsList.unshift(this.featuredSubject(s, true));
                }
                this.subjectsList.sort((a, b) => a.rank - b.rank); // b - a for reverse sort
              }
            });
            resolve(true);
          });
      }
    });
  }

  async loadSubjectData(subjectKey: string) {
    return new Promise(async (resolve, reject) => {
      if (this.currentSubjectKey !== subjectKey) {
        this.currentSubjectKey = subjectKey;
        this.currentSubjectCode = await this.authService.getSubjectCodeFromKey(
          this.currentSubjectKey
        );

        await this.getQuestionsForSubject(); // Once
        await this.getMCQsForSubject(); // Once
        await this.loadUserReviews(); // Subscribe
        await this.getPapers(subjectKey);
        await this.getUsersAttempts();

        const questionsCompleteSet = new Set(this.questionsComplete);
        const userAttemptsSet = new Set(
          this.userAttempts.map((attempt) => attempt.questionKey)
        );
        this.papers.forEach((paper) => {
          if (paper.questionData) {
            paper.questionData.sort((a, b) => a.number - b.number);
            paper.questionData.forEach((question) => {
              question.attempted = questionsCompleteSet.has(question.key);
              question.savedButNotComplete =
                userAttemptsSet.has(question.key) &&
                !questionsCompleteSet.has(question.key);
            });
          }
        });
      }
      resolve(true);
    });
  }

  getSessionStart() {
    const year = new Date().getFullYear();
    let sessionStart = new Date(year, 0, 1).getTime();
    if (new Date().getMonth() > 5) {
      sessionStart = new Date(year, 6, 1).getTime();
    }
    return new Date(sessionStart).getTime();
  }

  calculateScore(value) {
    if (value) {
      return (100 * value) / 10;
    } else {
      return 0;
    }
  }

  createExamAttempt(paper: Paper) {
    const id = this.authService.fs.createId();
    const exam = {
      id,
      paper: paper.paperIdString,
      paperId: paper.id,
      subjectId: paper.subjectId,
      month: paper.month,
      year: paper.year,
      paperNumber: paper.paperNumber,
    };
    this.authService.fs
      .collection("exam-attempts")
      .doc(exam.id)
      .set(exam)
      .then(() => {
        this.authService.router.navigate(["/exam/" + exam.id]);
      });
  }

  updateExamAttempt(attemptObject, id) {
    return this.authService.fs
      .collection("exam-attempts")
      .doc(id)
      .update(attemptObject);
  }

  updateExamAttemptReviews(reviewsObject, id) {
    return this.authService.fs
      .collection("exam-attempts")
      .doc(id)
      .update(reviewsObject);
  }

  loadExamAttempt(examId: string) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection("exam-attempts")
        .doc(examId)
        .get()
        .subscribe((examAttemptObj) => {
          let examAttempt = examAttemptObj.data() as ExamReview;
          resolve(examAttempt);
        });
    });
  }

  getUsersAttempts() {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(
          "attempts",
          (ref) => ref.where("uid", "==", this.authService.userInfo.uid)
          // .where("timestamp", ">=", this.getSessionStart())
        )
        .get()
        .subscribe(
          (querySnapshot) => {
            let attempts = [];
            querySnapshot.forEach((doc) => {
              // @ts-ignore
              attempts.push(doc.data() as AttemptText);
            });
            this.userAttempts = attempts;
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loadUserReviews() {
    return new Promise(async (resolve, reject) => {
      this.reviewsCollection = this.authService.fs
        .collection("reviews", (ref) =>
          ref
            .where("subject", "==", this.currentSubjectCode)
            .where("user", "==", this.authService.userInfo.uid)
            .where("time_submitted", ">=", this.getSessionStart())
            .orderBy("time_submitted", "desc")
            .orderBy("questionNumber", "desc")
        )
        .snapshotChanges();
      try {
        this.reviewsCollection.unsubscribe();
      } catch (e) { }

      this.reviewsCollection.subscribe(async (reviews) => {
        this.reviews = [];
        this.questionsComplete = [];
        reviews.forEach((r) => {
          const review = r.payload.doc.data() as Review;
          review["key"] = r.payload.doc.id;
          review["show"] = false; // this needs to be changed when we stop subscribing on the review collection.
          const questionIndex = this.allSubjectQuestions.findIndex(
            (q) => q.key === review.questionKey
          );
          if (questionIndex > -1 && review.state === 2) {
            this.allSubjectQuestions[questionIndex].performance = review.score
              ? review.score + 1
              : 0;
          }
          review["structure_concise"] = this.calculateScore(
            review["structure_score"]["concise"]
          );
          review["structure_idea_generated"] = this.calculateScore(
            review["structure_score"]["idea_generated"]
          );
          review["structure_info_used"] = this.calculateScore(
            review["structure_score"]["info_used"]
          );
          review["structure_key_ideas"] = this.calculateScore(
            review["structure_score"]["key_ideas"]
          );
          review["attemptTextReviewed"] = review["attemptTextReviewed"]
            ? review["attemptTextReviewed"]
            : review["attemptText"];
          if (review.hasOwnProperty("partsToBeMarked")) {
            let partsToBeMarkedDescr = "";
            for (const part of review["partsToBeMarked"]) {
              if (partsToBeMarkedDescr === "") {
                partsToBeMarkedDescr = part["identifier"];
              } else {
                partsToBeMarkedDescr =
                  partsToBeMarkedDescr + ", " + part["identifier"];
              }
            }
            review["partsToBeMarkedDescr"] = partsToBeMarkedDescr;
          }
          this.reviews.push(review);
          this.questionsComplete.push(review.questionKey);
        });
        resolve(true);
      });
    });
  }

  async getSubjectKeyFromCode(subjectCodeLowerCase): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.afs
        .collection("subjects", (ref) =>
          ref.where("code", "==", subjectCodeLowerCase.toLowerCase())
        )
        .get()
        .subscribe((d) => {
          d.forEach((subject) => {
            resolve(subject.data()["key"] as string);
          });
        });
    });
  }

  getQuestionsForSubject(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afs
        .collection("questions", (ref) =>
          ref.where("subject_key", "==", this.currentSubjectKey)
        )
        .snapshotChanges()
        .subscribe((data) => {
          this.allSubjectQuestions = [];
          const questions: QuestionData[] = [];
          data.forEach((question) => {
            const q = question.payload.doc.data() as any;
            q["key"] = question.payload.doc.id;
            q["performance"] = 0;
            q["question_marks"] = q["marks"];
            questions.push(q as QuestionData);
          });
          this.allSubjectQuestions = questions;
          resolve(true);
        });
    });
  }

  getMCQsForSubject(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afs
        .collection("multiple_choice", (ref) =>
          ref.where("subjectId", "==", this.currentSubjectKey)
        )
        .snapshotChanges()
        .subscribe((data) => {
          this.allSubjectMCQs = [];
          const questions: MultipleChoiceQuestion[] = [];
          data.forEach((question) => {
            const q = question.payload.doc.data() as any;
            q["key"] = question.payload.doc.id;
            questions.push(q as MultipleChoiceQuestion);
          });
          this.allSubjectMCQs = questions;
          resolve(true);
        });
    });
  }

  fieldSorter(fields) {
    return (a, b) =>
      fields
        .map((o) => {
          let dir = 1;
          if (o[0] === "-") {
            dir = -1;
            o = o.substring(1);
          }
          if (a[o] === undefined || b[o] === undefined) {
            throw new Error(
              'Error: field "' + o + '" does not exist on object.'
            );
          }
          return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
        })
        .reduce((p, n) => (p ? p : n), 0);
  }

  fieldSorterReverse(fields) {
    return (b, a) =>
      fields
        .map((o) => {
          let dir = 1;
          if (o[0] === "-") {
            dir = -1;
            o = o.substring(1);
          }
          return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
        })
        .reduce((p, n) => (p ? p : n), 0);
  }

  async getPapers(subjectId: string) {
    const papers: Paper[] = [];
    this.afs
      .collection("papers", (ref) => ref.where("subjectId", "==", subjectId))
      .get()
      .subscribe((d) => {
        d.forEach((paper) => {
          const p = paper.data() as Paper;
          p.questionData = this.allSubjectQuestions.filter(
            (q) => q.paperId === p.id
          );
          papers.push(p);
        });
        this.papers = papers.sort(
          this.fieldSorter(["year", "session", "paperNumber"])
        );
      });
  }

  async getIncompleteExamAttempts(subjectId) {
    return new Promise<ExamReview[]>(async (resolve, reject) => {
      this.examAttemptsUnfinished = [];
      this.afs
        .collection("exam-attempts", (ref) =>
          ref
            .where("subjectId", "==", subjectId)
            .where("user", "==", this.authService.userInfo.uid)
            .where("state", "in", [
              ExamStates.NON_STARTED,
              ExamStates.IN_PROGRESS,
              ExamStates.COMPLETED,
            ])
            .orderBy("timestampSaved", "desc")
        )
        .get()
        .subscribe((d) => {
          d.forEach((attempt) => {
            this.examAttemptsUnfinished.push(attempt.data() as ExamReview);
          });
          resolve(this.examAttemptsUnfinished);
        });
    });
  }

  sortByKey(array: QuestionData[], key: string) {
    return array.sort(function (a, b) {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  getSubjectTopics(subjectId) {
    return this.afs
      .collection("topics", (ref) => ref.where("exam_key", "==", subjectId))
      .snapshotChanges();
  }

  async topicImportance(subjectCode) {
    return new Promise(async (resolve, reject) => {
      let subjectKey = await this.getSubjectKeyFromCode(subjectCode);
      let topics = {};
      // @ts-ignore
      topics = await this.getSubjectTopicsLevelTwo(subjectKey);
      let topicImportance = [];
      let totalQuestions = 0;
      let maxRepresentation = 0;

      for (let key in topics) {
        totalQuestions = totalQuestions + topics[key]["questions"].length;
      }

      for (let key in topics) {
        if (
          topics[key]["questions"].length / totalQuestions >
          maxRepresentation
        ) {
          maxRepresentation = topics[key]["questions"].length / totalQuestions;
        }
        // @ts-ignore
        topicImportance.push({
          topic: topics[key]["topic"],
          // @ts-ignore
          questions: topics[key]["questions"].length,
          // @ts-ignore
          portion_representation:
            topics[key]["questions"].length / totalQuestions,
        });
      }

      for (let i in topicImportance) {
        // @ts-ignore
        topicImportance[i]["topicImportance"] = Math.round(
          (topicImportance[i]["portion_representation"] / maxRepresentation) *
          100
        );
      }

      topicImportance.sort((a, b) => {
        // @ts-ignore
        return b.topicImportance - a.topicImportance;
      });

      resolve(topicImportance);
    });
  }

  getTotalQuestionsForSubject(subjectCode) {
    return new Promise(async (resolve, reject) => {
      let questionCount = 0;
      let questionArray = [];
      let subjectKey = await this.getSubjectKeyFromCode(
        subjectCode.toLowerCase()
      );
      this.getSubjectTopics(subjectKey).subscribe((topicObj) => {
        topicObj.forEach((t) => {
          let topicData = t.payload.doc.data();
          if (
            // @ts-ignore
            topicData["questions"] != undefined &&
            // @ts-ignore
            topicData["questions"].length > 0
          ) {
            // @ts-ignore
            questionArray = questionArray.concat(topicData["questions"]);
          }
        });
        let questionArrayUnique = this.uniq(questionArray);
        questionCount = questionArrayUnique.length;
        resolve(questionCount);
      });
    });
  }

  getSubjectTopicsLevelTwo(subjectId) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection("topics", (ref) =>
          ref.where("exam_key", "==", subjectId).where("level", "==", 2)
        )
        .get()
        .subscribe((topicsObj) => {
          const topics = {};
          topicsObj.forEach((topicObj) => {
            const topicData = topicObj.data();
            topics[topicData["key"]] = topicData;
          });
          resolve(topics);
        });
    });
  }

  loadQuestionParts(
    questionId: string | undefined = undefined,
    dev: Boolean = false,
    parts: any = undefined
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let collection = "questions";
      if (dev) {
        collection = "questions_dev";
      }
      if (questionId) {
        this.afs
          .collection(collection)
          .doc(questionId)
          .get()
          .subscribe((questionObject) => {
            // @ts-ignore
            let parts = questionObject.data()["parts"];
            parts.forEach((part, index) => {
              parts[index]["question_raw"] = parts[index]["question_html"];
              parts[index]["question_html"] = this.formatQuestionHtml(
                part["question_html"],
                part["identifier"]
              );
            });
            resolve(parts);
          });
      } else {
        parts.forEach((part, index) => {
          parts[index]["question_raw"] = parts[index]["question_html"];
          parts[index]["question_html"] = this.formatQuestionHtml(
            part["question_html"],
            part["identifier"]
          );
        });
        resolve(parts);
      }
    });
  }

  checkIfQuestionIsAssignment(questionKey): Promise<Assignment | null> {
    return new Promise((resolve, reject) => {
      let question: QuestionData;
      this.afs
        .collection("assignments", (ref) =>
          ref
            .where("questionKey", "==", questionKey)
            .where("active", "==", true)
        )
        .get()
        .subscribe(async (assignments) => {
          let assignmentData: Assignment | null = null;
          assignments.forEach((assignment) => {
            assignmentData = assignment.data() as Assignment;
          });
          resolve(assignmentData);
        });
    });
  }

  async getExamQuestions(
    paperRef: string,
    subjectId: string
  ): Promise<QuestionData[]> {
    return new Promise((resolve, reject) => {
      const questions: QuestionData[] = this.allSubjectQuestions
        .filter(
          (question) =>
            question.subject_key === subjectId && question.paper === paperRef
        )
        .sort((a, b) => a.number - b.number);
      const partsPromises = questions.map(async (question) => {
        question.parts = await this.loadQuestionParts(
          undefined,
          false,
          question.parts
        );
        return question;
      });
      Promise.all(partsPromises).then((questionsWithParts) => {
        resolve(questionsWithParts);
      });
    });
  }

  uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
      return !pos || item !== ary[pos - 1];
    });
  }

  formatQuestionHtml(questionHtml, identifier) {
    let questionHtmlFormatted = questionHtml.replace(
      "<p>",
      '<p style="margin-top: 0px; margin-bottom: -2px">'
    );
    for (let i = 0; i <= 3; i++) {
      questionHtmlFormatted = questionHtmlFormatted.replace(
        `${identifier}.`,
        ""
      );
      questionHtmlFormatted = questionHtmlFormatted.replace(
        `${identifier}. `,
        ""
      );
      questionHtmlFormatted = questionHtmlFormatted.replace(
        `${identifier})`,
        ""
      );
      questionHtmlFormatted = questionHtmlFormatted.replace(
        `${identifier}) `,
        ""
      );
      questionHtmlFormatted = questionHtmlFormatted.replace(
        `<p>${identifier}.&nbsp;</p>`,
        ""
      );
      questionHtmlFormatted = questionHtmlFormatted.replace(
        `<p>${identifier}.</p>`,
        ""
      );
    }
    return this.sanitizer.bypassSecurityTrustHtml(questionHtmlFormatted);
  }

  limitQText(parts, characters) {
    var review_q = parts[0];
    review_q["question_html"] = review_q["question_html"].replace("&nbsp", "");
    review_q["question_html"] = review_q["question_html"].replace(";", "");
    var text_end_at_mark = review_q.question_raw.indexOf("[");
    var text_end_at_break = review_q.question_raw.indexOf("</p>");
    var text_end = text_end_at_break;

    if (text_end_at_mark != -1 && text_end_at_mark < text_end_at_break) {
      text_end = text_end_at_mark;
    }
    let review_q_dis = review_q.question_raw.substring(0, text_end);
    if (text_end >= characters) {
      review_q_dis = review_q_dis.substring(0, characters) + "..." + "</p>";
    } else {
      review_q_dis = review_q_dis.substring(0, text_end) + "</p>";
    }
    return review_q_dis;
  }

  getQuestionPreview(parts, characters) {
    if (!parts || parts.length === 0 || !parts[0].question_raw) {
      return "";
    }
    const reviewQuestion = parts[0];
    let questionPreview = reviewQuestion.question_raw
      .replace("&nbsp;", " ")
      .replace(";", "");
    const cutoffIndex = Math.min(
      questionPreview.indexOf("["),
      questionPreview.indexOf("</p>"),
      characters
    );
    questionPreview = questionPreview.substring(0, cutoffIndex);
    if (cutoffIndex > 0 && cutoffIndex < questionPreview.length) {
      questionPreview += "...";
    }
    questionPreview += "</p>";
    return questionPreview;
  }

  removeLastPart(inputString) {
    const lastIndex = inputString.lastIndexOf("_");

    if (lastIndex === -1) {
      // if '_' is not found in the string
      return inputString;
    }

    return inputString.substring(0, lastIndex);
  }

  loadPodcasts(): Promise<Podcast[]> {
    return new Promise((resolve, reject) => {
      this.afs
        .collection("podcasts", (ref) =>
          ref.where("subjectcode", "array-contains", this.currentSubjectCode)
        )
        .get()
        .subscribe(
          (querySnapshot) => {
            let podcasts: Podcast[] = []; // Explicitly define the type of the array
            querySnapshot.forEach((doc) => {
              podcasts.push(doc.data() as Podcast);
            });
            resolve(podcasts);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  recordPodcastClick(podcastInfo) {
    const podcastClick = {
      link: podcastInfo.link,
      podcast_title: podcastInfo.title,
      podcast_id: podcastInfo.id,
      uid: this.authService.userDetails.uid,
      user_email: this.authService.userDetails.email,
      timestamp: new Date().getTime(),
    };

    this.afs
      .collection("podcast_clicks")
      .add(podcastClick)
      .then(() => { })
      .catch((error) => console.error("Error recording podcast click:", error));
  }

  getSubject(subjectId: string) {
    return this.afs.collection("subjects").doc(subjectId).get();
  }

  async getFeesConfig() {
    const config = await this.afs.collection("config").doc('fees').get().toPromise();
    return config.data() as Fees;
  }

  async getAiConfig() {
    const config = await this.afs.collection("config").doc('ai').get().toPromise();
    return config.data() as AiConfig;
  }
}
